<template>
  <div id="base-plan" class="mb-10">
    <div>
      <b-card no-body>
        <b-card-text>
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 class="p-2 mt-2">Material Detail</h5>
              <hr />
              <div class="row mt-4">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Name</label>
                    <input
                      type="text"
                      v-model="name"
                      class="form-control"
                      :class="[errors['name'] ? 'border border-danger' : '']"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Type</label>
                    <select
                      class="form-control"
                      :class="[errors['m_type'] ? 'border border-danger' : '']"
                      id="exampleFormControlSelect1"
                      v-model="m_type"
                    >
                      <option value="">Select Type</option>
                      <option
                        v-for="(item, index) in material_type"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" style="background: white">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">S No.</th>
                    <th scope="col">Parameter</th>
                    <th scope="col">Specification</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in details"
                    :key="index"
                    :class="[item.error == 1 ? 'row_red' : '']"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_parameter_' + index"
                        v-model="item.parameter"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_specification_' + index"
                        v-model="item.specification"
                        class="form-control"
                      />
                    </td>
                    <td
                      class="lastcol"
                      style="width: 9%; background-color: white"
                    >
                      <button
                        v-if="details.length - 1 != 0"
                        class="btn btn-danger btn-sm mr-1"
                        @click="removeplan(index)"
                      >
                        Delete
                      </button>
                      <button
                        v-if="index == details.length - 1"
                        class="btn btn-success btn-sm"
                        @click="planpush()"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="btn btn-smplan-fixed">
            <button
              v-if="isEdit"
              class="btn btn-success"
              @click="update('kt_update_service')"
              style="float: right"
              ref="kt_update_service"
            >
              <i class="fa fa-check"></i>Update
            </button>
            <button
              v-if="!isEdit"
              class="btn btn-success ml-3"
              style="float: right"
              @click="save('S', 'kt_save_service')"
              ref="kt_save_service"
            >
              <i class="fa fa-check"></i>Save
            </button>
            <button
              v-if="!isEdit"
              class="btn btn-success"
              style="float: right"
              @click="save('SAA', 'kt_save_and_add_service')"
              ref="kt_save_and_add_service"
            >
              <i class="fa fa-check"></i>Save and Add Another
            </button>

            <button
              ref="kt_save_add_another_company"
              class="btn btn-danger mr-3"
              @click="goBack()"
              style="float: right"
            >
              <i class="fa fa-arrow-left"></i> Back
            </button>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import "vue2-datepicker/index.css";
import {
  CREATE_MATERIAL_DETAILS,
  FETCH_MATERIAL_TYPE_LISTS,
  FETCH_SELF_MATERIAL_DETAIL,
} from "@/core/services/store/actions.type";
// import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      isEdit: false,
      dateoptions: [{ text: "Today", onClick: () => new Date() }],

      details: [],
      id: 0,
      temp_start_time: "",
      temp_end_time: "",
      old_temp_start_time: 0,
      results: [],
      material_type: [],
      m_type: "",
      errors: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Create Packing Material Analysis Report",
          route: "",
        },
      ],
      name: "",
      add_status: 1,
      error_status: 1,
    };
  },
  created() {
    this.$store.dispatch(FETCH_MATERIAL_TYPE_LISTS).then((data) => {
      this.material_type = data.data;
    });
    this.isEdit = this.$route.name == "client.materials.edit" ? true : false;
    var id = this.$route.params.id;

    if (this.isEdit) {
      this.breadCrumbs[1].title = "Update Packing Material Report";
      this.$store
        .dispatch(FETCH_SELF_MATERIAL_DETAIL, id)
        .then((data) => {
          this.details = data.data.details;
          this.name = data.data.name;
          this.m_type = data.data.material_type;
        })
        .catch(() => {
          this.$toastr.e("detail not found!");
          this.$router.push({ name: "client.qualitycontrol.packingmaterials" });
        });
    } else {
      this.planpush(1);
    }

    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    save(saveType, ref) {
      this.errors = [];

      this.loadingButton(ref);
      const det = this.details.filter((data) => {
        return data.parameter != "" || data.specification != "";
      });
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.name == "") {
        this.errors["name"] = "This field is required";
        err = 1;
      }
      if (this.m_type == "") {
        this.errors["m_type"] = "This field is required";
        err = 1;
      }
      if (err == 1) {
        this.$toastr.w(msg);
        this.closeBtnLoad(ref);
        return 0;
      }
      if (det.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        this.closeBtnLoad(ref);
        return 0;
      }
      var formData = {
        details: det,
        name: this.name,
        m_type: this.m_type,
        id: this.$route.params.id,
      };

      // const submitButton = this.$refs["kt_submit_base_plan"];
      // submitButton.disabled = true;
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(CREATE_MATERIAL_DETAILS, formData)
        .then((res) => {
          this.closeBtnLoad(ref);
          this.$toastr.s(res.msg);
          if (saveType == "S") {
            this.$router.push({
              name: "client.materials",
            });
          } else if (saveType == "SAA") {
            this.reset();
          }
        })
        .catch(() => {
          this.closeBtnLoad(ref);
          this.$toastr.e("Something went wrong !");
        });
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    update(ref) {
      this.errors = [];
      this.loadingButton(ref);
      const det = this.details.filter((data) => {
        return data.parameter != "" || data.specification != "";
      });
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.name == "") {
        this.errors["name"] = "This field is required";
        err = 1;
      }
      if (this.m_type == "") {
        this.errors["m_type"] = "This field is required";
        err = 1;
      }
      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      if (det.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        return 0;
      }
      var formData = {
        details: det,
        name: this.name,
        m_type: this.m_type,
        id: this.$route.params.id,
      };

      // const submitButton = this.$refs["kt_submit_base_plan"];
      // submitButton.disabled = true;
      // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(CREATE_MATERIAL_DETAILS, formData)
        .then((res) => {
          this.closeBtnLoad(ref);
          // submitButton.classList.remove(
          //   "spinner",
          //   "spinner-light",
          //   "spinner-right"
          // );
          // submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.materials",
          });
          //   if (this.$route.params.client == "sp") {
          //     this.getLocationwisebasePlan();
          //   } else {
          //     this.fetchbasePlan();
          //   }
        })
        .catch(() => {
          this.closeBtnLoad(ref);
          this.$toastr.e("Something went wrong !");
        });
    },
    removeplan(index) {
      this.details.splice(index, 1);
    },
    planpush() {
      let plan = {
        parameter: "",
        specification: "",
      };
      this.details.push(plan);
      this.add_status = 1;
    },

    reset() {
      this.name = "";
      this.add_status = 1;
      this.error_status = 1;
      this.details = [
        {
          parameter: "",
          specification: "",
        },
      ];
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}
.mx-datepicker .mx-input {
  height: 2.8rem;
}
.row_red {
  background-color: #f89494 !important;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.btn-smplan-fixed {
  position: fixed;
  top: 93%;
  background-color: #ffff;
  width: calc(71vw - 0%);

  button {
    float: right;
  }
}
table {
  .baseplan-header {
    background: #b4cfe2;
  }
}
</style>
<style>
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.45rem;
}
.mx-input {
  height: 3rem;
}
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 10%;
}
.ino {
  width: 10%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
</style>
